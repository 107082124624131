import axios from "axios";
import Config from "../../config/config.json";
import { clearToken, setToken } from "../../library/Utilities";
import { GatesSelect } from "../Gates/Gates";

export function handleSignIn(username, password) {
  return async (dispatch) => {
    dispatch(SignInRequest());
    return axios
      .post(`${Config.api.endpoint}/authenticate`, {
        email: username,
        password: password,
      })
      .then((response) => {
        const allowedRoles = ["siteadmin", "gateguard"];
        if (response.data && !allowedRoles.includes(response.data.role)) {
          dispatch(SignInFailure("You Do Not Have The Required Permissions. Please Contact Your Company Admin."));
        } else {
          dispatch(SignInSuccess(response.data));
        }
      })
      .catch((error) => {
        dispatch(
          SignInFailure(error.response.data && (error.response.data.message || "Sign-In Failed. Please Try Again."))
        );
      });
  };
}

export function Verify(token) {
  return async (dispatch) => {
    dispatch(VerifyRequest(token));
    return axios
      .get(`${Config.api.endpoint}/users/self`)
      .then((response) => {
        dispatch(VerifySuccess(response.data.data));
        // push gate to redux
        const gate = sessionStorage.getItem("gate");
        if (gate) {
          dispatch(GatesSelect(JSON.parse(gate)));
        }
      })
      .catch((error) => {
        dispatch(VerifyFailure());
      });
  };
}

export const VERIFY_REQUEST = "VERIFY_REQUEST";
export function VerifyRequest(token) {
  setToken(token);
  return {
    type: VERIFY_REQUEST,
  };
}

export const VERIFY_SUCCESS = "VERIFY_SUCCESS";
export function VerifySuccess(data) {
  return {
    type: VERIFY_SUCCESS,
    data,
  };
}

export const VERIFY_FAILURE = "VERIFY_FAILURE";
export function VerifyFailure() {
  clearToken();
  return {
    type: VERIFY_FAILURE,
  };
}

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export function SignInRequest(data) {
  return {
    type: SIGNIN_REQUEST,
    data,
  };
}

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export function SignInSuccess(data) {
  setToken(data.access_token);
  return {
    type: SIGNIN_SUCCESS,
    data,
  };
}

export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export function SignInFailure(error) {
  return {
    type: SIGNIN_FAILURE,
    error,
  };
}

export const SETUSER = "SETUSER";
export function SetUser(data) {
  return {
    type: SETUSER,
    data,
  };
}

export const SIGNOUT = "SIGNOUT";
export function SignOut() {
  clearToken();
  return {
    type: SIGNOUT,
  };
}
