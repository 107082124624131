import { CLEAR_GATES, GATES_REQUEST, GATES_SELECT, GATES_SUCCESS, GATE_DIRECTION } from "../../actions/Gates/Gates";

const InitialState = {
  data: [],
  id: null,
};

export default function gateService(state = InitialState, action) {
  switch (action.type) {
    case GATES_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
      };
    case GATES_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data,
      };
    case GATES_SELECT:
      return {
        ...state,
        busy: false,
        res: true,
        id: action.data.id,
        direction: action.data.direction,
      };
    case GATE_DIRECTION:
      return {
        ...state,
        direction: action.data,
      };
    case CLEAR_GATES:
      return {
        busy: false,
        res: true,
        data: [],
        id: null,
      };
    default:
      return state;
  }
}
