import {
  SETUSER,
  SIGNIN_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNOUT,
  VERIFY_FAILURE,
  VERIFY_REQUEST,
  VERIFY_SUCCESS,
} from "../../actions/Auth/Auth";

const InitialState = {
  busy: false,
  auth: null,
  error: false,
  role: null,
  permissions: [],
  token: null,
};

export default function authService(state = InitialState, action) {
  switch (action.type) {
    case SIGNIN_REQUEST:
      return {
        busy: true,
        auth: false,
      };
    case SIGNIN_SUCCESS:
      return {
        busy: false,
        auth: true,
        role: action.data.role,
        site: action.data.network[0].company[0].site[0].id,
        permissions: action.data.permissions,
        networkId: action.data.network[0].id,
      };
    case SIGNIN_FAILURE:
      return {
        busy: false,
        auth: false,
        error: action.error,
      };
    case VERIFY_REQUEST:
      return {
        busy: false,
      };
    case VERIFY_SUCCESS:
      return {
        busy: false,
        auth: true,
        role: action.data.role,
        site: action.data.network[0].company[0].site[0].id,
        permissions: action.data.permissions,
        networkId: action.data.network[0].id,
      };
    case VERIFY_FAILURE:
      return {
        busy: false,
        auth: false,
        role: null,
      };
    case SETUSER:
      return {
        ...state,
        ...action.data,
      };
    case SIGNOUT:
      return {
        auth: false,
        token: null,
      };
    default:
      return state;
  }
}
