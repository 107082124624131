import React from "react";
import { Card } from "reactstrap";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #c1c1c1;
`;

const Text = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const Warning = styled.i`
  font-size: 72px;
  color: #c1c1c1;
  margin: 10px;
`;

const styles = {
  maxWidth: "75%",
  padding: "10px",
};

const Platform = () => (
  <Container>
    <Card style={styles}>
      <Text>
        <Warning className="fal fa-exclamation-triangle"></Warning>
        <p>Chrome not supported on iOS. Please use Safari instead.</p>
      </Text>
    </Card>
  </Container>
);

export default Platform;
