import { combineReducers } from "redux";
import authService from "./Auth/Auth";
import gateService from "./Gates/Gates";
import scanService from "./RecentScans/RecentScans";
import musterService from "./Muster/Muster";
import forgotPasswordService from "./ForgotPassword/ForgotPassword";

const rootReducer = combineReducers({
  authService,
  gateService,
  scanService,
  musterService,
  forgotPasswordService,
});

export default rootReducer;
