import {
  GET_MUSTER_LIST_FAILURE,
  GET_MUSTER_LIST_REQUEST,
  GET_MUSTER_LIST_SUCCESS,
  GET_MUSTER_LOCATION_FAILURE,
  GET_MUSTER_LOCATION_REQUEST,
  GET_MUSTER_LOCATION_SUCCESS,
  MUSTER_LOCATION_SELECT,
} from "../../actions/Muster/Muster";

const InitialState = {
  data: {
    absent: [],
    present: [],
  },
  locations: [],
  id: null,
  busy: null,
  res: null,
  err: null,
};

export default function musterService(state = InitialState, action) {
  switch (action.type) {
    case GET_MUSTER_LIST_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        err: false,
      };

    case GET_MUSTER_LIST_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        err: false,
        data: action.data.data.muster,
      };

    case GET_MUSTER_LIST_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        err: true,
      };

    case GET_MUSTER_LOCATION_REQUEST:
      return {
        ...state,
        busy: true,
        res: false,
        err: false,
      };

    case GET_MUSTER_LOCATION_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        err: false,
        locations: action.data.data.locations,
      };

    case GET_MUSTER_LOCATION_FAILURE:
      return {
        ...state,
        busy: false,
        res: false,
        err: true,
      };

    case MUSTER_LOCATION_SELECT:
      return {
        ...state,
        id: action.id,
      };

    default:
      return state;
  }
}
