import {
  CHECK_COMPLIANCE_REQUEST,
  CHECK_COMPLIANCE_SUCCESS,
  CHECK_IN_REQUEST,
  CHECK_IN_SUCCESS,
  OVERRIDE_RESPONSE,
  RECENTSCANS_REQUEST,
  RECENTSCANS_SUCCESS,
  SCANSEARCH_CLEAR,
  SCANSEARCH_FAILURE,
  SCANSEARCH_REQUEST,
  SCANSEARCH_SUCCESS,
  SET_NETWORK_ID,
} from "../../actions/RecentScans/RecentScans";

const InitialState = {
  data: [],
  results: [],
  networkId: null,
  allow_access: {
    access: false,
    label: null,
  },
  selectedStudent: null,
  isCompliant: null,
  checkingCompliance: false,
  complianceResponse: false,
  searching: false,
  status: null,
  orstatus: null,
  message: null,
  reasons: [],
};

export default function scanService(state = InitialState, action) {
  switch (action.type) {
    case OVERRIDE_RESPONSE:
      return {
        ...state,
        ores: action.data,
      };
    case RECENTSCANS_REQUEST:
      return {
        ...state,
        busy: true,
        ores: false,
        res: false,
        data: state.data,
      };
    case RECENTSCANS_SUCCESS:
      return {
        ...state,
        busy: false,
        res: true,
        data: action.data,
      };
    case SET_NETWORK_ID:
      return {
        ...state,
        networkId: action.networkId,
      };
    case SCANSEARCH_CLEAR:
      return {
        ...state,
        results: [],
      };
    case SCANSEARCH_REQUEST:
      return {
        ...state,
        searching: true,
        ores: false,
        res: false,
        message: null,
        results: [],
      };
    case SCANSEARCH_SUCCESS:
      return {
        ...state,
        searching: false,
        res: true,
        results: action.data.data.results,
      };
    case SCANSEARCH_FAILURE:
      return {
        ...state,
        searching: false,
        err: true,
        res: false,
        message: action.err,
      };
    case CHECK_IN_REQUEST:
      return {
        ...state,
        busy: true,
        ores: false,
        res: false,
        selectedStudent: action.id,
      };
    case CHECK_COMPLIANCE_SUCCESS:
      return {
        ...state,
        isCompliant: action.data.data.allow_access,
        checkingCompliance: false,
        complianceResponse: true,
        reasons: action.data.data.deny_reasons,
      };
    case CHECK_COMPLIANCE_REQUEST:
      return {
        ...state,
        ores: false,
        res: false,
        checkingCompliance: true,
      };
    case CHECK_IN_SUCCESS:
      return {
        ...state,
        busy: false,
        err: false,
        res: true,
        allow_access: {
          access: action.data.data.allow_access,
          label: action.data.data.allow_access ? "Checked-In" : "Access Denied",
        },
        status: action.direction === "in" ? "Checked-In" : "Checked-Out",
      };
    default:
      return state;
  }
}
