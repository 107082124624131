import React, { Component } from "react";
import { connect } from "react-redux";
import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { Verify, VerifyFailure } from "./actions/Auth/Auth";
import "./App.scss";
import GA from "./components/GoogleAnalytics";
import Platform from "./components/Platform";

const loading = () => (
  <div className="loading-wrapper d-flex justify-content-center">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Page404 = React.lazy(() => import("./views/Pages/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500"));
const SignIn = React.lazy(() => import("./views/Pages/SignIn"));
const Support = React.lazy(() => import("./views/Pages/Support"));
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));

// Scanner
const Scan = React.lazy(() => import("./views/Scanner/Scan"));

// simple component to call route render when authed, otherwise return redirect.
const PrivateRoute = ({ auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (auth === true) {
          return rest.render(props);
        }

        if (auth === false) {
          return (
            <Redirect
              to={{
                pathname: "/sign-in",
                state: { from: rest.location },
              }}
            />
          );
        }
      }}
    />
  );
};

class App extends Component {
  constructor(props) {
    super(props);
    // soft verify if there is a token in sessionStorage
    const { Verify, VerifyFailure } = this.props;

    const token = sessionStorage.getItem("token");

    if (token) {
      Verify(token);
    } else {
      VerifyFailure();
    }
  }

  render() {
    const { auth } = this.props;

    if (navigator.userAgent.match("CriOS")) {
      return <Platform />;
    }

    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          {GA.init() && <GA.RouteTracker />}
          <Switch>
            <Route exact path="/404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/500" render={(props) => <Page500 {...props} />} />
            <Route exact path="/sign-in" render={(props) => <SignIn {...props} />} />
            <Route exact path="/support" render={(props) => <Support {...props} />} />
            <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} />} />
            <PrivateRoute auth={auth} exact path="/scan" name="Scan" render={(props) => <Scan {...props} />} />
            <PrivateRoute auth={auth} exact path="/scan/:action" name="Scan" render={(props) => <Scan {...props} />} />
            <PrivateRoute auth={auth} path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.authService.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Verify: (token) => dispatch(Verify(token)),
    VerifyFailure: () => dispatch(VerifyFailure()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
