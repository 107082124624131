import axios from "axios";
import Config from "../../config/config.json";

export function forgotPassword(email, history) {
  if (!email) {
    return (dispatch) => dispatch(ForgotPasswordFailure("Please enter a valid email."));
  }

  return async (dispatch) => {
    dispatch(ForgotPasswordRequest());
    return axios
      .post(`${Config.api.endpoint}/forgot`, {
        email: email,
      })
      .then((res) => {
        dispatch(ForgotPasswordSuccess("Success!"));
        history.push("/sign-in");
      })
      .catch((err) => {
        dispatch(ForgotPasswordFailure("Please enter a valid email."));
      });
  };
}

export const FORGOTPASSWORD_REQUEST = "FORGOTPASSWORD_REQUEST";
export function ForgotPasswordRequest() {
  return {
    type: FORGOTPASSWORD_REQUEST,
  };
}

export const FORGOTPASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS";
export function ForgotPasswordSuccess(res) {
  return {
    type: FORGOTPASSWORD_SUCCESS,
    res,
  };
}

export const FORGOTPASSWORD_FAILURE = "FORGOTPASSWORD_FAILURE";
export function ForgotPasswordFailure(error) {
  return {
    type: FORGOTPASSWORD_FAILURE,
    error,
  };
}

export const FORGOTPASSWORD_ACK = "FORGOTPASSWORD_ACK";
export function ForgotPasswordAck() {
  return {
    type: FORGOTPASSWORD_ACK,
  };
}
