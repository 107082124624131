import axios from "axios";

export function saveDirection(data) {
  const gate = sessionStorage.getItem("gate");
  if (gate) {
    let json = JSON.parse(gate);
    json.direction = data;
    sessionStorage.setItem("gate", JSON.stringify(json));
  }
}

export function setLocal(key, value) {
  sessionStorage.setItem(key, JSON.stringify(value));
}

export function getLocal(key) {
  const value = sessionStorage.getItem(key);

  return JSON.parse(value);
}

export function setToken(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  sessionStorage.setItem("token", token);
}

export function getToken() {
  return sessionStorage.getItem("token");
}

export function clearToken() {
  axios.defaults.headers.common["Authorization"] = "";
  sessionStorage.removeItem("token");
}

export function debounce(func, wait, immediate) {
  var timeout;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function setInterceptors() {
  axios.interceptors.response.use(null, noAuthInterceptor);
}

function noAuthInterceptor(error) {
  if (error.response.status === 401) {
    window.location = "/";
  }

  return Promise.reject(error);
}

export function getDeviceId() {
  const cookie = document.cookie.match("(^|;)\\s*".concat("scandit-device-id", "\\s*=\\s*([^;]+)"));

  if (null == cookie) {
    return "";
  }

  const n = cookie.pop();

  return null == n ? "" : n;
}
