import React from "react";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import Package from "../package.json";
import App from "./App";
import "./index.css";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import rootReducer from "./reducers/index";
import * as serviceWorker from "./serviceWorker";

// Log Application and Version to Console
console.log(`Application: ${Package.name}`);
console.log(`Version: ${Package.version}`);

const composeMiddleware = () => {
  let middleware = [applyMiddleware(thunk)];

  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    middleware.push(window.__REDUX_DEVTOOLS_EXTENSION__());
  }

  return compose(...middleware);
};

const store = createStore(rootReducer, composeMiddleware());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
