import axios from "axios";
import Config from "../../config/config.json";
import { saveDirection } from "../../library/Utilities";

export function handleGates() {
  return async (dispatch) => {
    dispatch(GatesRequest());
    return axios
      .get(`${Config.api.endpoint}/gates`)
      .then((response) => {
        dispatch(GatesSuccess(response.data.data.gates));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export const GATES_REQUEST = "GATES_REQUEST";
export function GatesRequest() {
  return {
    type: GATES_REQUEST,
  };
}

export const GATES_SUCCESS = "GATES_SUCCESS";
export function GatesSuccess(data) {
  return {
    type: GATES_SUCCESS,
    data,
  };
}

export const GATES_SELECT = "GATES_SELECT";
export function GatesSelect(data) {
  return {
    type: GATES_SELECT,
    data,
  };
}

export const GATE_DIRECTION = "GATE_DIRECTION";
export function gateDirection(data) {
  saveDirection(data);
  return {
    type: GATE_DIRECTION,
    data,
  };
}

export const CLEAR_GATES = "CLEAR_GATES";
export function ClearGates() {
  return {
    type: CLEAR_GATES,
  };
}
